<template>
  <DetailTemplate
    :customClass="'task-detail detail-page'"
    v-if="getPermission('task:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            {{ taskSubject }}
          </h1>
          <Priority :priority.sync="taskDetail.priority"></Priority>
          <CustomStatus
            :status.sync="taskDetail.status"
            endpoint="tasks/status"
          ></CustomStatus>
          <custom-chip
            v-if="getTaskCheckStatus()"
            :text="getTaskCheckStatus()"
            color="green lighten-2"
            text-color="white"
          ></custom-chip>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <v-skeleton-loader
        class="custom-skeleton d-inline-block"
        v-if="pageLoading"
        type="button"
      >
      </v-skeleton-loader>
      <v-menu
        v-else-if="moreActions.length"
        transition="slide-y-transition"
        bottom
        content-class="custom-menu-list"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :disabled="!formValid || formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-bind="attrs"
            v-on="on"
          >
            More... <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list>
          <template v-for="(more, index) in moreActions">
            <v-list-item
              link
              v-on:click="updateMoreAction(more.action)"
              :key="index"
              :disabled="more.disabled"
            >
              <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-500 font-size-14">{{
                more.title
              }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-container fluid>
        <v-row>
          <v-col md="12">
            <v-tabs
              v-model="taskTab"
              background-color="transparent"
              color="cyan"
              class="custom-tab-transparent"
            >
              <template v-for="(tab, index) in tabs">
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  :key="index"
                  :href="'#' + tab.key"
                  :disabled="tab.disabled"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  {{ tab.title }}
                </v-tab>
              </template>
            </v-tabs>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll"
              style="max-height: 64vh; position: static"
            >
              <v-tabs-items v-model="taskTab">
                <v-tab-item value="task">
                  <v-layout class="my-4">
                    <v-flex md6 class="mx-4 custom-border-right">
                      <table class="width-100">
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Task #
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            {{ taskDetail.barcode }}
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Reference #
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.reference">{{
                              taskDetail.reference
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Reference #</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Subject
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.subject">{{
                              taskDetail.subject
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Subject</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Hourly Rate
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.hourly_rate">{{
                              taskDetail.hourly_rate
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Hourly Rate</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Planned Start Date
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.start_date">{{
                              formatDate(taskDetail.start_date)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Start Date</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Planned Due Date
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.due_date">{{
                              formatDate(taskDetail.due_date)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Due Date</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Planned Duration
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.planned_duration"
                              >{{ taskDetail.planned_duration }} Days</template
                            >
                            <template v-else
                              ><em class="text--secondary"
                                >No Duration</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Actual Start Date
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.started_date">{{
                              formatDate(taskDetail.started_date)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >Not Started Yet</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Actual Finish Date
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.finish_date">{{
                              formatDate(taskDetail.finish_date)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >Not Finished Yet</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Actual Duration
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.actual_duration"
                              >{{ taskDetail.actual_duration }} Days</template
                            >
                            <template v-else
                              ><em class="text--secondary"
                                >No Duration</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr>
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Task Cost
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.task_cost">{{
                              formatMoney(taskDetail.task_cost)
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Task Cost</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Recurrence
                            <template v-if="taskDetail.recurrence == 8"
                              >(Custom)</template
                            >
                          </td>
                          <td class="pb-2 font-size-18 font-weight-600">
                            <template v-if="taskDetail.recurrence != 8">
                              <template v-if="taskDetail.recurrence_text">{{
                                taskDetail.recurrence_text
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Recurrence</em
                                ></template
                              >
                            </template>
                            <template v-else>
                              <template v-if="taskDetail.duration_type_text"
                                >Every {{ taskDetail.duration }}
                                {{ taskDetail.duration_type_text }}</template
                              >
                              <template v-else
                                ><em class="text--secondary"
                                  >No Recurrence</em
                                ></template
                              >
                            </template>
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Total Logged Hours
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.total_logged_hours">{{
                              taskDetail.total_logged_hours
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Time Logged Till Now</em
                              ></template
                            >
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td
                            width="200"
                            class="pb-2 font-size-18 text-capitalize"
                          >
                            Total Cycle
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <template v-if="taskDetail.total_cycle">{{
                              taskDetail.total_cycle
                            }}</template>
                            <template v-else
                              ><em class="text--secondary"
                                >No Total Cycle</em
                              ></template
                            >
                          </td>
                        </tr>
                      </table>

                      <div
                        class="mt-4 custom-border-top"
                        v-if="taskDetail.lead_relation"
                      >
                        <h3 class="py-2 font-size-18 text-capitalize">Lead</h3>
                        <table>
                          <tr>
                            <td
                              width="200"
                              class="pb-2 font-size-18 text-capitalize"
                            >
                              Lead #
                            </td>
                            <td
                              class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            >
                              <template>{{
                                taskDetail.lead_relation.barcode
                              }}</template>
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="200"
                              class="pb-2 font-size-18 text-capitalize"
                            >
                              Lead Title
                            </td>
                            <td
                              class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            >
                              <template>{{
                                taskDetail.lead_relation.title
                              }}</template>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div
                        class="mt-4 custom-border-top"
                        v-if="taskDetail.customer_relation"
                      >
                        <h3 class="py-2 font-size-18 text-capitalize">
                          Customer
                        </h3>
                        <table>
                          <tr>
                            <td
                              width="200"
                              class="pb-2 font-size-18 text-capitalize"
                            >
                              Customer #
                            </td>
                            <td
                              class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            >
                              <template>{{
                                taskDetail.customer_relation.barcode
                              }}</template>
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="200"
                              class="pb-2 font-size-18 text-capitalize"
                            >
                              Display Name
                            </td>
                            <td
                              class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            >
                              <template>{{
                                taskDetail.customer_relation.display_name
                              }}</template>
                            </td>
                          </tr>
                        </table>
                      </div>
                      <div
                        class="mt-4 custom-border-top"
                        v-if="taskDetail.project_relation"
                      >
                        <h3 class="py-2 font-size-18 text-capitalize">
                          Project
                        </h3>
                        <table>
                          <tr>
                            <td
                              width="200"
                              class="pb-2 font-size-18 text-capitalize"
                            >
                              Project #
                            </td>
                            <td
                              class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            >
                              <template>{{
                                taskDetail.project_relation.barcode
                              }}</template>
                            </td>
                          </tr>
                          <tr>
                            <td
                              width="200"
                              class="pb-2 font-size-18 text-capitalize"
                            >
                              Project Name
                            </td>
                            <td
                              class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            >
                              <template>{{
                                taskDetail.project_relation.name
                              }}</template>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </v-flex>
                    <v-flex md6 class="mx-4">
                      <div class="mt-4">
                        <h3 class="py-2 font-size-18 text-capitalize">
                          Description
                        </h3>
                        <template v-if="taskDetail.description">
                          <read-more
                            class="custom-read-more font-size-18 pb-1"
                            more-str="read more"
                            :text="taskDetail.description"
                            link="#"
                            less-str="read less"
                            :max-chars="200"
                          >
                          </read-more>
                        </template>
                        <template v-else
                          ><em class="text--secondary"
                            >No description for this task</em
                          ></template
                        >
                      </div>
                      <div class="mt-5 custom-border-top">
                        <v-layout class="py-2">
                          <v-flex>
                            <h3 class="font-size-18 text-capitalize">
                              members
                            </h3>
                          </v-flex>
                          <v-flex
                            class="text-right"
                            v-if="
                              getPermission('task:update') &&
                              taskDetail.status > 1
                            "
                          >
                            <v-btn icon v-on:click="memberDialog = true">
                              <v-icon color="cyan">mdi-cog</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                        <template
                          v-if="
                            taskDetail.members && taskDetail.members.length > 0
                          "
                        >
                          <v-list-item
                            v-for="(member, index) in taskDetail.members"
                            :key="index"
                          >
                            <v-list-item-avatar>
                              <v-img
                                contain
                                :lazy-src="$defaultProfileImage"
                                :src="$assetAPIURL(member.profile_logo)"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title class="font-size-18">{{
                                member.full_name
                              }}</v-list-item-title>
                              <v-list-item-subtitle
                                v-if="false"
                                class="font-size-14 text-capitalize line-height-1-5"
                                >total logged hours :
                                {{
                                  lodash.toSafeInteger(
                                    member.time_logged_in_minutes / 60
                                  )
                                }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                            <v-list-item-action
                              v-if="
                                getPermission('task:delete') &&
                                taskDetail.status > 1
                              "
                            >
                              <v-btn icon v-on:click="deleteMember(member)">
                                <v-icon color="red lighten-1"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                        <template v-else>
                          <template v-if="!pageLoading">
                            <p
                              class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                            >
                              <img
                                width="30"
                                :src="$assetURL('media/error/empty.png')"
                                class="row-not-found-image mx-2"
                              />
                              Uhh... There are no member associated<br />with
                              this task at the moment.
                            </p>
                          </template>
                        </template>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-tab-item>
                <v-form>
                  <v-tab-item value="checklist-item">
                    <v-container fluid>
                      <v-layout>
                        <v-flex>
                          <v-btn
                            class="my-2 mr-2 custom-bold-button white--text"
                            color="cyan"
                            small
                            :disabled="isPageLoading"
                            v-on:click="addCheckListItem"
                          >
                            Add Item
                          </v-btn>
                          <v-btn
                            v-if="newChecklistItems.length > 0"
                            class="my-2 ml-2 custom-bold-button white--text"
                            color="cyan"
                            small
                            :disabled="isPageLoading"
                            v-on:click="saveCheckListItem"
                          >
                            Save Item
                          </v-btn>
                        </v-flex>
                      </v-layout>
                      <table
                        class="width-100"
                        v-if="
                          newChecklistItems.length > 0 ||
                          checklistItems.length > 0
                        "
                      >
                        <tr
                          v-for="(row, index) in newChecklistItems"
                          :key="index"
                        >
                          <td text-align="center" class="px-2 py-0" width="50">
                            <v-checkbox
                              color="cyan"
                              class="hide-margin"
                              hide-details
                              v-model.number="row.status"
                              :value="1"
                              :disabled="isPagenLoading"
                            ></v-checkbox>
                          </td>
                          <td text-align="center" class="px-2 py-0">
                            <v-container>
                              <v-row>
                                <v-col
                                  cols="12"
                                  lg="4"
                                  sm="6"
                                  md="3"
                                  class="pt-0"
                                >
                                  <div>
                                    <label
                                      for="lead_title"
                                      class="font-weight-700 font-size-16"
                                      style="margin: 10px"
                                      >Subject</label
                                    >
                                    <v-text-field
                                      v-model.trim="row.subject"
                                      dense
                                      filled
                                      label="Subject"
                                      solo
                                      flat
                                      color="cyan"
                                      hide-details
                                      :readonly="row.id"
                                      :disabled="isPageLoading"
                                      :loading="isPageLoading"
                                    ></v-text-field>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="12"
                                  lg="4"
                                  sm="6"
                                  md="3"
                                  class="pt-0"
                                >
                                  <div>
                                    <label
                                      for="lead_title"
                                      class="font-weight-700 font-size-16"
                                      style="margin: 10px"
                                      >Start Date</label
                                    >
                                    <Datepicker
                                      solo
                                      nullable
                                      mandatory
                                      placeholder="Start date"
                                      v-model="row.start_date"
                                      :min-date="minDate"
                                    ></Datepicker>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="12"
                                  lg="4"
                                  sm="6"
                                  md="3"
                                  class="pt-0"
                                >
                                  <div>
                                    <label
                                      for="lead_title"
                                      class="font-weight-700 font-size-16"
                                      style="margin: 10px"
                                      >End Date</label
                                    >
                                    <Datepicker
                                      solo
                                      clearable
                                      nullable
                                      mandatory
                                      placeholder="End Date"
                                      :min-date="row.start_date"
                                      v-model="row.last_date"
                                    ></Datepicker>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col lg="8" md="8" sm="6" class="pt-0">
                                  <CheckListAttachment
                                    v-on:file:updated="
                                      (param) =>
                                        updateCheckListAttachment(param, index)
                                    "
                                    :attachments="[]"
                                    :label="true"
                                    :isMultiple="false"
                                    allowUpload
                                  ></CheckListAttachment>
                                </v-col>
                                <v-col lg="4" md="4" sm="6" class="pt-0">
                                  <label
                                    for="lead_title"
                                    class="font-weight-700 font-size-16"
                                    style="margin: 10px"
                                    >Members</label
                                  >
                                  <v-autocomplete
                                    dense
                                    color="cyan"
                                    filled
                                    :items="checkListMemberList"
                                    :disabled="pageLoading"
                                    :loading="pageLoading"
                                    v-model.trim="row.members"
                                    label="Members"
                                    solo
                                    flat
                                    item-color="cyan"
                                    item-text="full_name"
                                    item-value="id"
                                    hide-details
                                    multiple
                                  >
                                    <template
                                      v-slot:selection="{ item, index }"
                                    >
                                      <v-chip v-if="index === 0">
                                        <span>{{ item.full_name }}</span>
                                      </v-chip>
                                      <span
                                        v-if="index === 1"
                                        class="grey--text font-size-12"
                                      >
                                        (+{{ row.members.length - 1 }}
                                        others)
                                      </span>
                                    </template>
                                    <template v-slot:no-data>
                                      <v-list-item>
                                        <v-list-item-content>
                                          <v-list-item-title
                                            v-html="'No Member Found.'"
                                          ></v-list-item-title>
                                        </v-list-item-content>
                                      </v-list-item>
                                    </template>
                                  </v-autocomplete>
                                </v-col>
                              </v-row>
                            </v-container>
                          </td>
                          <td text-align="center" class="px-2 py-0" width="50">
                            <v-btn
                              icon
                              :disabled="isPageLoading"
                              v-on:click="removeCheckListItem(index)"
                            >
                              <v-icon color="red lighten-1">mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        <tr v-for="(row, index) in checklistItems" :key="index">
                          <td text-align="center" class="px-2 py-0" width="50">
                            <v-checkbox
                              color="cyan"
                              class="hide-margin"
                              hide-details
                              v-model.number="row.status"
                              :value="1"
                              :disabled="isPageLoading"
                              v-on:change="updateCheckListItem(row)"
                            ></v-checkbox>
                          </td>
                          <td text-align="center" class="px-2 py-0">
                            <v-container>
                              <v-row>
                                <v-col
                                  cols="12"
                                  lg="4"
                                  sm="6"
                                  md="3"
                                  class="pt-0"
                                >
                                  <div>
                                    <label
                                      for="lead_title"
                                      class="font-weight-700 font-size-16"
                                      style="margin: 10px"
                                      >Subject</label
                                    >
                                    <v-text-field
                                      v-model.trim="row.subject"
                                      dense
                                      filled
                                      label="Subject"
                                      solo
                                      flat
                                      color="cyan"
                                      hide-details
                                      :readonly="row.id"
                                      :disabled="isPageLoading"
                                      :loading="isPageLoading"
                                    ></v-text-field>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="12"
                                  lg="4"
                                  sm="6"
                                  md="3"
                                  class="pt-0"
                                >
                                  <div>
                                    <label
                                      for="lead_title"
                                      class="font-weight-700 font-size-16"
                                      style="margin: 10px"
                                      >Start Date</label
                                    >
                                    <Datepicker
                                      solo
                                      placeholder="Start date"
                                      v-model="row.start_date"
                                      :min-date="minDate"
                                    ></Datepicker>
                                  </div>
                                </v-col>
                                <v-col
                                  cols="12"
                                  lg="4"
                                  sm="6"
                                  md="3"
                                  class="pt-0"
                                >
                                  <div>
                                    <label
                                      for="lead_title"
                                      class="font-weight-700 font-size-16"
                                      style="margin: 10px"
                                      >End Date</label
                                    >
                                    <Datepicker
                                      solo
                                      clearable
                                      placeholder="End Date"
                                      :min-date="row.start_date"
                                      v-model="row.last_date"
                                    ></Datepicker>
                                  </div>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col lg="8" md="8" sm="6" class="pt-4">
                                  <CheckListAttachment
                                    v-on:file:updated="
                                      (param) =>
                                        updateCheckListAttachment(param, index)
                                    "
                                    :label="true"
                                    :attachments="row.attachments"
                                    :isMultiple="false"
                                    allowUpload
                                  ></CheckListAttachment>
                                </v-col>
                                <v-col lg="4" md="4" sm="6" class="pt-4">
                                  <div>
                                    <label
                                      for="lead_title"
                                      class="font-weight-700 font-size-16"
                                      style="margin: 10px"
                                      >Members</label
                                    >
                                    <v-autocomplete
                                      dense
                                      color="cyan"
                                      filled
                                      :items="checkListMemberList"
                                      :disabled="pageLoading"
                                      :loading="pageLoading"
                                      v-model.trim="row.members"
                                      label="Members"
                                      solo
                                      flat
                                      item-color="cyan"
                                      item-text="full_name"
                                      item-value="id"
                                      hide-details
                                      multiple
                                    >
                                      <template
                                        v-slot:selection="{ item, index }"
                                      >
                                        <v-chip v-if="index === 0">
                                          <span>{{ item.full_name }}</span>
                                        </v-chip>
                                        <span
                                          v-if="index === 1"
                                          class="grey--text font-size-12"
                                        >
                                          (+{{ row.members.length - 1 }}
                                          others)
                                        </span>
                                      </template>
                                      <template v-slot:no-data>
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title
                                              v-html="'No Member Found.'"
                                            ></v-list-item-title>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </template>
                                    </v-autocomplete>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>

                            <!-- <v-text-field
                            v-model.trim="row.subject"
                            dense
                            filled
                            label="Subject"
                            solo
                            flat
                            color="cyan"
                            hide-details
                            :disabled="isPageLoading"
                            :loading="isPageLoading"
                            @change="updateCheckListItem(row)"
                          ></v-text-field> -->
                          </td>
                          <td text-align="center" class="px-2 py-0" width="50">
                            <v-btn
                              icon
                              :disabled="isPageLoading"
                              v-on:click="deleteCheckListItem(row.id)"
                            >
                              <v-icon color="red lighten-1">mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </table>

                      <v-col md="12" v-else>
                        <template v-if="!pageLoading">
                          <p
                            class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              width="30"
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image mx-2"
                            />
                            Uhh... There are no checklist items for this task at
                            the moment.
                          </p>
                        </template>
                      </v-col>
                    </v-container>
                  </v-tab-item>
                </v-form>
                <v-tab-item value="contractor">
                  <Contractors
                    :detail.sync="taskDetail"
                    type="task"
                  ></Contractors>
                </v-tab-item>
                <v-tab-item value="comment">
                  <v-container fluid>
                    <v-layout v-if="taskDetail.status > 1">
                      <v-flex>
                        <v-btn
                          v-on:click="addComment"
                          class="my-2 mr-2 custom-bold-button white--text"
                          color="cyan"
                          small
                          :disabled="commentLoading"
                        >
                          <template v-if="commentActive">Close</template>
                          <template v-else>Add Comment</template>
                        </v-btn>
                        <v-btn
                          v-if="commentActive"
                          class="my-2 ml-2 custom-bold-button white--text"
                          color="cyan"
                          small
                          :disabled="commentLoading"
                          v-on:click="saveComment"
                        >
                          Save Comment
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-row>
                      <template v-if="commentActive">
                        <v-col md="12">
                          <editor v-model="commentCreate.description" />
                        </v-col>
                        <v-col md="12">
                          <AttachmentWithName
                            v-on:file:updated="updateCommentAttachment"
                            :label="true"
                            allowUpload
                          ></AttachmentWithName>
                        </v-col>
                      </template>
                      <v-col md="12" v-if="comments.length > 0">
                        <v-timeline dense clipped>
                          <v-timeline-item
                            color="white"
                            v-for="(row, index) in comments"
                            :key="index"
                            large
                          >
                            <template v-slot:icon>
                              <v-avatar>
                                <v-img
                                  contain
                                  :src="row.profile_logo"
                                  :lazy-src="$defaultProfileImage"
                                ></v-img>
                              </v-avatar>
                            </template>
                            <v-row class="pt-1">
                              <v-col md="3">
                                <em
                                  v-tippy="{ arrow: true }"
                                  content="Created At"
                                  class="font-weight-600"
                                  >{{ formatDateTime(row.added_at) }}</em
                                ><br />
                                <em
                                  v-tippy="{ arrow: true }"
                                  content="Created By"
                                  class="font-weight-600 text-capitalize"
                                  >{{ row.full_name }}</em
                                >
                              </v-col>
                              <v-col md="9">
                                <div class="task-comment-delete">
                                  <v-col col="12" class="pr-5">
                                    <v-btn
                                      icon
                                      :disabled="isPageLoading"
                                      v-on:click="deleteComment(row.id)"
                                    >
                                      <v-icon color="red lighten-1"
                                        >mdi-delete</v-icon
                                      >
                                    </v-btn>
                                  </v-col>
                                </div>
                                <div class="caption mb-2">
                                  <read-more
                                    class="custom-read-more font-weight-600 font-size-16 pb-1 pr-2"
                                    more-str="read more"
                                    :text="row.description || ''"
                                    link="#"
                                    less-str="read less"
                                    :max-chars="60"
                                  >
                                  </read-more>
                                </div>
                                <AttachmentWithName
                                  :label="true"
                                  :attachments.sync="row.attachments"
                                ></AttachmentWithName>
                              </v-col>
                            </v-row>
                          </v-timeline-item>
                        </v-timeline>
                      </v-col>
                      <v-col md="12" v-else>
                        <template v-if="!pageLoading">
                          <p
                            class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              width="30"
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image mx-2"
                            />
                            Uhh... There are no comment for this task at the
                            moment.
                          </p>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
                <v-tab-item value="attachment">
                  <v-container fluid>
                    <v-layout v-if="taskDetail.status > 1">
                      <v-flex>
                        <v-btn
                          v-on:click="addAttachment"
                          class="my-2 mr-2 custom-bold-button white--text"
                          color="cyan"
                          small
                        >
                          Add Attachment
                        </v-btn>
                        <v-btn
                          v-if="attachmentActive"
                          class="my-2 ml-2 custom-bold-button white--text"
                          color="cyan"
                          small
                          v-on:click="saveAttachment"
                        >
                          Save Attachment
                        </v-btn>
                      </v-flex>
                    </v-layout>
                    <v-row>
                      <v-col md="12" v-if="attachmentActive">
                        <AttachmentWithName
                          v-on:file:updated="updateAttachment"
                          :label="true"
                          allowUpload
                        ></AttachmentWithName>
                      </v-col>
                      <v-col md="12" v-if="attachments.length > 0">
                        <AttachmentView
                          :attachments="attachments"
                        ></AttachmentView>
                      </v-col>
                      <v-col md="12" v-else>
                        <template v-if="!pageLoading">
                          <p
                            class="m-0 text--secondary row-not-found text-center font-weight-500 font-size-16"
                          >
                            <img
                              width="30"
                              :src="$assetURL('media/error/empty.png')"
                              class="row-not-found-image mx-2"
                            />
                            Uhh... There are no attachment for this task at the
                            moment.
                          </p>
                        </template>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </perfect-scrollbar>
          </v-col>
        </v-row>
      </v-container>
      <template v-if="getPermission('task:update')">
        <Dialog :commonDialog.sync="memberDialog">
          <template v-slot:title>Members</template>
          <template v-slot:body>
            <v-container class="py-0">
              <v-row>
                <v-col md="12" class="py-0">
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    :items="memberList"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    v-model.trim="members"
                    label="Members"
                    solo
                    flat
                    item-color="cyan"
                    item-text="full_name"
                    item-value="id"
                    hide-details
                    multiple
                  >
                    <template v-slot:selection="{ item, index }">
                      <v-chip v-if="index < 3">
                        <span>{{ item.full_name }}</span>
                      </v-chip>
                      <span v-if="index === 3" class="grey--text font-size-12">
                        (+{{ members.length - 3 }} others)
                      </span>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Member Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:action>
            <v-container class="py-0">
              <v-row>
                <v-col md="12" class="text-right">
                  <v-btn
                    v-on:click="memberDialog = false"
                    :disabled="pageLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    class="mx-2 custom-bold-button white--text"
                    v-on:click="updateMembers"
                    color="cyan"
                  >
                    Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </Dialog>
      </template>
      <template v-if="getPermission('task:delete')">
        <MemberDelete :deleteDialog="deleteMemberDialog">
          <template v-slot:title>Delete Confirmation</template>
          <template v-slot:text>
            <h3 class="font-size-16 font-weight-500 delete-text">
              Once you delete
              <span class="font-weight-700">{{ deleteDetail.full_name }}</span
              >, you won't be able to retrieve this later. Are you sure you want
              to delete
              <span class="font-weight-700">{{ deleteDetail.full_name }}</span>
              ?
            </h3>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="deleteLoading"
              :loading="deleteLoading"
              class="mx-2 custom-bold-button white--text"
              color="red lighten-1"
              v-on:click="deleteMemberConfirm"
            >
              Yes, Delete it
            </v-btn>

            <v-btn
              :disabled="deleteLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="deleteMemberDialog = false"
            >
              No, Keep it
            </v-btn>
          </template>
        </MemberDelete>
      </template>
    </template>
    <template v-slot:footer> </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET, PATCH, POST, DELETE } from "@/core/services/store/request.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import Priority from "@/view/pages/partials/Priority";
import Dialog from "@/view/pages/partials/Dialog";
import MemberDelete from "@/view/pages/partials/Delete.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";
import CustomChip from "@/view/pages/partials/CustomChip";
import Contractors from "@/view/pages/partials/Detail/Contractor";
import Datepicker from "@/view/pages/partials/Datepicker";
import AttachmentView from "@/view/pages/partials/AttachmentView";
import AttachmentWithName from "@/view/pages/partials/AttachmentWithName";
import CheckListAttachment from "@/view/pages/task/Detail/CheckListAttachment";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "task-detail",
  title: "Detail Task",
  data() {
    return {
      task: null,
      minDate: new Date().toISOString().substr(0, 10),
      taskTab: "checklist-item",
      pageLoading: false,
      deleteLoading: false,
      commentLoading: false,
      checklistLoading: false,
      attachmentLoading: false,
      attachmentActive: false,
      commentActive: false,
      memberDialog: false,
      deleteMemberDialog: false,
      moreActions: [],
      memberList: [],
      checkListMemberList: [],
      members: [],
      newChecklistItems: [],
      checklistItems: [],
      comments: [],
      attachments: [],
      commentCreate: new Object({
        description: null,
        attachments: [],
      }),
      attachmentCreate: new Object({
        attachments: [],
      }),
      deleteDetail: new Object(),
      taskDetail: new Object({
        id: null,
        customer_relation: null,
        lead_relation: null,
        project_relation: null,
        subject: null,
        //start_date:null,
        description: null,
        barcode: null,
        reference: null,
        hourly_rate: null,
        priority: 0,
        status: 0,
        pending_checklist: 0,
        total_checklist: 0,
        task_cost: 0,
        completed_percentage: null,
        recurrence: null,
        duration: null,
        duration_type: null,
        total_cycle: null,
        start_date: null,
        last_date: null,
        finish_date: null,
        attachments: [],
        members: [],
        due_date: null,
        userList: new Array(),
      }),
      tabs: [
        /*{
          title: "Overview",
          icon: "mdi-phone",
          key: "task",
          disabled: false,
        },*/
        {
          title: "Checklist Items",
          icon: "mdi-phone",
          key: "checklist-item",
          disabled: false,
        },
        /* {
          title: "Contractors",
          icon: "mdi-account-multiple",
          key: "contractor",
          disabled: false,
        }*/
        {
          title: "Comments",
          icon: "mdi-account-multiple",
          key: "comment",
          disabled: false,
        },
        /*{
          title: "Reminders",
          icon: "mdi-account-multiple",
          key: "reminder",
          disabled: false
        },*/
        {
          title: "Attachments",
          icon: "mdi-account-multiple",
          key: "attachment",
          disabled: false,
        },
        /*{
          title: "History",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false
        }*/
      ],
    };
  },
  components: {
    DetailTemplate,
    Contractors,
    CustomChip,
    MemberDelete,
    CustomStatus,
    Priority,
    Dialog,
    editor: TinyMCE,
    AttachmentWithName,
    CheckListAttachment,
    AttachmentView,
    Datepicker,
  },
  methods: {
    getOptions() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "leads/options",
        })
        .then(({ data }) => {
          _this.userList = data.users;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getTaskCheckStatus() {
      if (this.taskDetail.completed_percentage) {
        return `${this.taskDetail.completed_percentage}% Completed`;
      }
      return false;
    },
    updateMoreAction(action) {
      const _this = this;
      switch (action) {
        case "duplicate":
          _this.$router.push(
            _this.getDefaultRoute("task.create", {
              query: {
                duplicate: _this.task,
              },
            })
          );
          break;
        case "edit":
          _this.$router.push(
            _this.getDefaultRoute("task.update", {
              params: {
                id: _this.task,
              },
            })
          );
          break;
        case "mark_as_completed":
          _this.updateTask(1);
          break;
        case "mark_as_testing":
          _this.updateTask(2);
          break;
        case "mark_as_inprogress":
          _this.updateTask(3);
          break;
        case "download_pdf":
          _this.downloadPdf();
          break;
        case "print":
          _this.printPage();
          break;
      }
    },
    updateTask(status) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "tasks/" + _this.task + "/status",
          data: { status },
        })
        .then(() => {
          _this.getTask();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    downloadPdf() {},
    printPage() {},
    deleteMember(member) {
      this.deleteDetail = member;
      this.deleteMemberDialog = true;
    },
    deleteMemberConfirm() {
      const _this = this;
      _this.deleteLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "tasks/" + _this.task + "/members/" + _this.deleteDetail.id,
        })
        .then(() => {
          _this.getTask();
          _this.deleteDetail = new Object();
          _this.deleteMemberDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.deleteLoading = false;
        });
    },
    updateMembers() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, {
          url: "tasks/" + _this.task + "/members",
          data: { members: _this.members },
        })
        .then(() => {
          _this.getTask();
          _this.memberDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    getMembers() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "tasks/members",
        })
        .then(({ data }) => {
          _this.memberList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCheckListMemberList() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + this.task + "/members",
        })
        .then(({ data }) => {
          _this.checkListMemberList = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getChecklistItems() {
      const _this = this;
      _this.checklistLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task + "/checklist-items",
        })
        .then(({ data }) => {
          _this.checklistItems = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.checklistLoading = false;
        });
    },
    addCheckListItem() {
      this.newChecklistItems.push({
        subject: null,
        start_date: null,
        last_date: null,
        members: [],
        images: null,
        attachments: [],
        status: null,
      });
    },
    removeCheckListItem(index) {
      this.newChecklistItems.splice(index, 1);
    },
    saveCheckListItem() {
      const _this = this;
      _this.checklistLoading = false;
      _this.$store
        .dispatch(POST, {
          url: "tasks/" + _this.task + "/checklist-items",
          data: { items: _this.newChecklistItems },
        })
        .then(() => {
          _this.newChecklistItems = new Array();
          _this.getChecklistItems();
          _this.getTask();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.checklistLoading = false;
        });
    },
    updateCheckListItem(row) {
      const _this = this;
      _this.checklistLoading = true;
      row.status = this.lodash.toSafeInteger(row.status);
      _this.$store
        .dispatch(PATCH, {
          url: "tasks/" + _this.task + "/checklist-items/" + row.id,
          data: row,
        })
        .then(() => {
          _this.newChecklistItems = new Array();
          _this.getChecklistItems();
          _this.getTask();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.checklistLoading = false;
        });
    },
    deleteCheckListItem(itemid) {
      const _this = this;
      _this.checklistLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "tasks/" + _this.task + "/checklist-items/" + itemid,
        })
        .then(() => {
          _this.getChecklistItems();
          _this.getTask();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.checklistLoading = false;
        });
    },
    getComments() {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task + "/comments",
        })
        .then(({ data }) => {
          _this.comments = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    addComment() {
      this.commentActive = !this.commentActive;
      this.commentCreate = new Object({
        description: null,
        attachments: [],
      });
    },
    updateCommentAttachment(param) {
      this.commentCreate.attachments = param;
    },
    updateCheckListAttachment(param, index) {
      console.log(param);
      this.newChecklistItems[index].images = param;
      //this.commentCreate.attachments = param;
    },
    saveComment() {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "tasks/" + _this.task + "/comments",
          data: _this.commentCreate,
        })
        .then(() => {
          _this.commentCreate = new Object({
            description: null,
            attachments: [],
          });
          _this.commentActive = false;
          _this.getComments();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    deleteComment(itemid) {
      const _this = this;
      _this.commentLoading = true;
      _this.$store
        .dispatch(DELETE, {
          url: "tasks/" + _this.task + "/comments/" + itemid,
        })
        .then(() => {
          _this.getComments();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.commentLoading = false;
        });
    },
    getAttachments() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task + "/attachments",
        })
        .then(({ data }) => {
          _this.attachments = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    addAttachment() {
      this.attachmentActive = !this.attachmentActive;
      this.attachmentCreate = new Object({
        attachments: [],
      });
    },
    updateAttachment(param) {
      this.attachmentCreate.attachments = param;
    },
    saveAttachment() {
      const _this = this;
      _this.attachmentLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "tasks/" + _this.task + "/attachments",
          data: _this.attachmentCreate,
        })
        .then(() => {
          _this.attachmentCreate = new Object({
            attachments: [],
          });
          _this.attachmentActive = false;
          _this.getAttachments();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.attachmentLoading = false;
        });
    },
    getTask() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "tasks/" + _this.task,
        })
        .then(({ data }) => {
          _this.moreActions = data.more_actions;
          _this.taskDetail = new Object({
            id: _this.task,
            customer_relation: data.customer_relation,
            lead_relation: data.lead_relation,
            project_relation: data.project_relation,
            subject: data.subject,
            description: data.description,
            barcode: data.barcode,
            reference: data.reference,
            hourly_rate: data.hourly_rate,
            priority: data.priority,
            planned_duration: data.planned_duration,
            actual_duration: data.actual_duration,
            status: data.status,
            recurrence: data.recurrence,
            recurrence_text: data.recurrence_text,
            duration: data.duration,
            pending_checklist: data.pending_checklist,
            total_checklist: data.total_checklist,
            started_date: data.started_date,
            completed_percentage: data.completed_percentage,
            task_cost: data.task_cost,
            duration_type: data.duration_type,
            duration_type_text: data.duration_type_text,
            total_cycle: data.total_cycle,
            start_date: data.start_date,
            last_date: data.last_date,
            finish_date: data.finish_date,
            attachments: data.attachments,
            due_date: data.due_date,
            members: data.members,
          });
          if (data.members && data.members.length > 0) {
            _this.members = data.members.map((row) => {
              return row.user_id;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Task", route: "task" },
      { title: "Detail" },
    ]);
    _this.task = _this.lodash.toSafeInteger(_this.$route.params.id);

    if (_this.task <= 0) {
      _this.goBack();
    }

    _this.getTask();
    _this.getMembers();
    _this.getChecklistItems();
    _this.getComments();
    _this.getAttachments();
    _this.getOptions();
    _this.getCheckListMemberList();
  },
  computed: {
    taskSubject() {
      return this.taskDetail.subject;
    },
    taskMemberList() {
      let result = this.memberList.map((row) => {
        if (this.lodash.includes(this.members, row.id)) {
          return row;
        }
        return null;
      });
      return this.lodash.compact(result);
    },
    isPageLoading() {
      if (
        this.deleteLoading ||
        this.checklistLoading ||
        this.commentLoading ||
        this.pageLoading
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
