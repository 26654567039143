<template>
  <!--begin::Contractor Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            placeholder="Search Contractor #, Display Name, Company"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllContractors"
            @keydown.tab="getAllContractors"
          ></v-text-field>
        </v-col>
        <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllContractors"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip v-if="true" top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="manageDialog = true"
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
            <span>Manage Contractors</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="6"
      :dataLoading.sync="dataLoading"
      :rowData.sync="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">Image</th>
            <th class="simple-table-th" width="100">Contractor #</th>
            <th class="simple-table-th" width="100">Contractor Info</th>
            <th class="simple-table-th" width="300">Contractor Address</th>
            <th class="simple-table-th" width="200">Created Time</th>
            <th class="simple-table-th" width="200" v-if="false">
              Last Modified Time
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody
          v-if="dataRows.length"
          class="custom-border-bottom custom-border-top"
        >
          <tr
            v-for="(data, index) in dataRows"
            :key="index"
            v-on:click="rowClicked(data)"
          >
            <td class="simple-table-td" width="100">
              <v-img
                contain
                class="contractor-image"
                width="70"
                height="70"
                :lazy-src="$defaultProfileImage"
                :src="getProfileImage(data.profile_logo)"
              >
              </v-img>
            </td>
            <td class="simple-table-td" width="100">
              <Barcode
                route="contractor.detail"
                :id="data.id"
                :barcode="data.barcode"
              ></Barcode>
            </td>
            <td class="simple-table-td" width="100">
              <div class="contractor-listing-contractor">
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Display Name: </b> {{ data.display_name }}
                </p>
                <p class="m-0 custom-nowrap-ellipsis">
                  <b>Company: </b> {{ data.company_name }}
                </p>
                <p
                  class="m-0 custom-nowrap-ellipsis"
                  v-if="lodash.isEmpty(data.default_person) === false"
                >
                  <b>Email: </b>
                  {{ data.default_person.primary_email }}
                </p>
                <p
                  class="m-0 custom-nowrap-ellipsis"
                  v-if="lodash.isEmpty(data.default_person) === false"
                >
                  <b>Phone: </b>
                  {{ data.default_person.primary_phone }}
                </p>
              </div>
            </td>
            <td class="simple-table-td" width="300">
              <div class="property-listing-contractor">
                <p
                  class="m-0 custom-nowrap-ellipsis-four-line word-break"
                  v-if="data.billing"
                >
                  {{ data.billing.property_address }}
                </p>
              </div>
            </td>
            <td class="simple-table-td" width="200">
              <TableActivity
                v-if="!lodash.isEmpty(data.added_by)"
                :data.sync="data"
              >
                <template v-slot:display_name>
                  {{ data.added_by.display_name }}
                </template>
                <template v-slot:date_time>
                  {{ data.created_at }}
                </template>
                <template v-slot:format_date_time>
                  {{ formatDateTime(data.added_at) }}
                </template>
              </TableActivity>
            </td>
            <td v-if="false" class="simple-table-td" width="200">
              <TableActivity
                v-if="!lodash.isEmpty(data.updated_by)"
                :data.sync="data"
              >
                <template v-slot:display_name>
                  {{ data.updated_by.display_name }}
                </template>
                <template v-slot:date_time>
                  {{ data.modified_at }}
                </template>
                <template v-slot:format_date_time>
                  {{ formatDateTime(data.updated_at) }}
                </template>
              </TableActivity>
            </td>
          </tr>
        </tbody>
        <tfoot v-else>
          <tr>
            <td colspan="6">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  width="40"
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mx-4 my-5"
                />
                Uhh... There are no contractor at the moment.
              </p>
            </td>
          </tr>
        </tfoot>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading.sync="dataLoading"
      :showingFrom.sync="showingFrom"
      :showingTo.sync="showingTo"
      :totalRows.sync="totalRows"
      :currentPage.sync="currentPage"
      :totalPages.sync="totalPages"
    ></ListingFooter>
    <Dialog :common-dialog="manageDialog" :dialog-width="dialogWidth">
      <template v-slot:title>Manage Contractors</template>
      <template v-slot:body>
        <v-container fluid>
          <v-row>
            <v-col md="6" class="p-0 pr-4 custom-border-right">
              <h4
                class="color-custom-blue text-uppercase font-weight-600 font-size-18 mb-3"
              >
                All Contractors
              </h4>
              <template v-if="listContractors.length">
                <v-list-item
                  two-line
                  v-for="(row, index) in listContractors"
                  :key="index"
                  link
                  v-on:click="selectContractor(index)"
                >
                  <v-list-item>
                    <v-list-item-avatar size="80">
                      <v-img
                        contain
                        class="contractor-image"
                        width="150"
                        height="150"
                        :lazy-src="$defaultProfileImage"
                        :src="getProfileImage(row.profile_logo)"
                      >
                      </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <Barcode :barcode="row.barcode"></Barcode>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-size-16"
                        ><b>Display Name: </b>
                        {{ row.display_name }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle class="font-size-16"
                        ><b>Company: </b>
                        {{ row.company_name }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-icon>mdi-arrow-right-bold-outline</v-icon>
                </v-list-item>
              </template>
              <p
                v-else
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  width="40"
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mx-4 my-5"
                />
                Uhh... There are no contractor at the moment.
              </p>
            </v-col>
            <v-col md="6" class="p-0 pl-4">
              <h4
                class="color-custom-blue text-uppercase font-weight-600 font-size-18 mb-3"
              >
                Selected Contractors
              </h4>
              <template v-if="selectedContractors.length">
                <v-list-item
                  two-line
                  v-for="(row, index) in selectedContractors"
                  :key="index"
                  link
                  v-on:click="removeContractor(index)"
                >
                  <v-icon>mdi-arrow-left-bold-outline</v-icon>
                  <v-list-item>
                    <v-list-item-avatar size="80">
                      <v-img
                        contain
                        class="contractor-image"
                        width="150"
                        height="150"
                        :lazy-src="$defaultProfileImage"
                        :src="getProfileImage(row.profile_logo)"
                      >
                      </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <Barcode :barcode="row.barcode"></Barcode>
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-size-16"
                        ><b>Display Name: </b>
                        {{ row.display_name }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle class="font-size-16"
                        ><b>Company: </b>
                        {{ row.company_name }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item>
              </template>
              <p
                v-else
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  width="40"
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image mx-4 my-5"
                />
                Uhh... There are no contractor selected at the moment.
              </p>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="manageDialog = false"
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!formValid || formLoading"
          :loading="formLoading"
          class="mx-2 custom-bold-button white--text"
          v-on:click="onSubmit"
          color="cyan"
        >
          Save
        </v-btn>
      </template>
    </Dialog>
  </v-container>
  <!--end::Contractor Internal Listing-->
</template>

<script>
import { QUERY, GET, PATCH } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";

export default {
  mixins: [CommonMixin],
  name: "contractor-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      listContractors: [],
      selectedContractors: [],
      dataLoading: true,
      formLoading: false,
      filter: {
        status: "all",
        search: null,
      },
      manageDialog: false,
      timeout: null,
      timeoutLimit: 500,
      totalPages: 0,
      currentPage: 0,
      totalRows: 0,
      rowsOffset: 0,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "product",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.parent = this.lodash.toSafeInteger(param.id);
        if (this.parent > 0) {
          this.getAllContractors();
        }
      },
    },
  },
  components: {
    Dialog,
    Barcode,
    TableActivity,
    ListingFooter,
    ListingTable,
  },
  methods: {
    selectContractor(index) {
      const contractor = this.lodash.cloneDeep(this.listContractors[index]);
      if (contractor) {
        const index = this.lodash.findIndex(this.selectedContractors, {
          id: contractor.id,
        });
        if (index < 0) {
          this.selectedContractors.push(contractor);
          const listIndex = this.lodash.findIndex(this.listContractors, {
            id: contractor.id,
          });
          this.listContractors.splice(listIndex, 1);
        } else {
          this.removeContractor(index);
        }
      }
    },
    removeContractor(index) {
      const contractor = this.lodash.cloneDeep(this.selectedContractors[index]);
      if (contractor) {
        const selectIndex = this.lodash.findIndex(this.selectedContractors, {
          id: contractor.id,
        });
        this.selectedContractors.splice(selectIndex, 1);
        const index = this.lodash.findIndex(this.listContractors, {
          id: contractor.id,
        });
        if (index < 0) {
          this.listContractors.push(contractor);
        }
      }
    },
    onSubmit() {
      this.formLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "contractor/update",
          data: {
            ids: this.selectedContractors.map((row) => row.id),
            type: this.type,
            type_id: this.parent,
          },
        })
        .then(() => {
          this.getAllContractors();
          this.manageDialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    getListContractors() {
      this.$store
        .dispatch(GET, { url: "contractor/all" })
        .then(({ data }) => {
          this.listContractors = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.validateContractors();
        });
    },
    validateContractors() {
      for (let i = this.selectedContractors.length - 1; i >= 0; i--) {
        const index = this.lodash.findIndex(this.listContractors, {
          id: this.selectedContractors[i].id,
        });
        if (index >= 0) {
          this.listContractors.splice(index, 1);
        }
      }
    },
    rowClicked(params) {
      this.$router.push(
        this.getDefaultRoute("contractor.detail", {
          params: { id: params.id },
        })
      );
    },
    getProfileImage(profile_logo) {
      if (profile_logo && profile_logo.file && profile_logo.file.url) {
        return profile_logo.file.url;
      }
      return null;
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllContractors();
    },
    getAllContractors() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        search: _this.filter.search || undefined,
        type: _this.type,
        type_id: _this.parent,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "contractor/transaction",
            data: filter,
          })
          .then((data) => {
            _this.selectedContractors = _this.lodash.cloneDeep(data.rows);
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_contractor / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_contractor;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
            _this.validateContractors();
          });
      }, _this.timeoutLimit);
    },
  },
  created() {
    this.parent = this.lodash.toSafeInteger(this.detail.id);
  },
  mounted() {
    this.getListContractors();
    this.getAllContractors();
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
